import { defineStore } from 'pinia';
import { ToastMessageOptions } from 'primevue/toast';

export const useToastStore = defineStore('Toast', {
    state: () => ({
        message: '',
        type: 'info' as ToastMessageOptions['severity'],
        life: 3000,
    }),

    getters: {

    },

    actions: {
        showToast(data: { message: string, type?: ToastMessageOptions["severity"], life?: number }) {
            const { message, type, life } = data
            this.message = message
            this.type = type || 'info'
            this.life = life || 3000
        },

        resetMessage() {
            this.message = ''
        },
    }
})