<template>
	<Toast 
		group="default"
		:pt="{
			content: 'flex items-center',
			detail: 'hidden',
			closeButton: 'hidden'
		}"
	/>

	<Toast 
		position="bottom-center" 
		group="bc"
		:pt="{
			container: 'w-[150px] mx-auto',
			content: 'flex items-center',
			detail: 'hidden',
			closeButton: 'hidden'
		}"
	/>

	<router-view />
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { defineComponent } from 'vue'
import { watch } from 'vue';
import { ToastMessageOptions } from 'primevue/toast';
import { useStore } from './store/main';
import { useToastStore } from './store/toast';

export default defineComponent({
	components: {},

	setup() {
		// const { t, locale } = useI18n();
		const { locale } = useI18n();

		watch(locale, (newlocale) => {
			localStorage.setItem('locale', newlocale);
			document.documentElement.setAttribute('lang', newlocale);
		});

		const store = useStore();

		const toastStore = useToastStore()

		return {
			// t,
			locale,
			store,
			toastStore,
		};
	},

	data() {
		return {
			appInterval: undefined as number | undefined,
		};
	},

	mounted() {
		const self = this;

		this.appInterval = setInterval(function () {
			const isRouteMetaLogin = self.$route.meta.isLogin;
			const isWindowLogin = window.isLogin;

			const token = localStorage.getItem('token');
			const tokenExpiredAt = parseInt(localStorage.getItem('tokenExpiredAt') as string);

			if (isRouteMetaLogin != undefined && isRouteMetaLogin === true) {
				if (!!isWindowLogin && !!token && !!tokenExpiredAt) {
					const now = new Date().valueOf();

					// console.log(`🚀 ~ tokenExpiredAt - now: ${new Date()} + ${tokenExpiredAt - now}`)
					
					if (tokenExpiredAt - now <= 30 * 60 * 1000) { // 每 30 分
						self.store.refreshToken();
					}

				} else {
					self.store.logout();
					self.$router.push('/');
				}

				// this.store.getUserProfile();
			}
		}, 60 * 1000); // 每 60 秒

		this.toastStore.$subscribe((mutation, state) => {
			// console.log("🚀 ~ this.toastStore.$subscribe ~ state:", state)
			const toast = {
				message: state.message,
				type: state.type,
				life: state.life,
			}
			if (!!state && !!state.message) {
				this.showToast(toast)
			}
			
		})
	},

	unmounted() {
		clearInterval(this.appInterval);
	},

	methods: {
		showToast(data: { message: string, type: ToastMessageOptions["severity"], life: number }) {
			const { message, type, life } = data
            let group = (this.isMobile) ? 'bc' : 'default';
            this.$toast.add({ severity: type, summary: message, life, group });
			this.toastStore.resetMessage()
        },
	},
});
</script>
