import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toast, {
      group: "default",
      pt: {
			content: 'flex items-center',
			detail: 'hidden',
			closeButton: 'hidden'
		}
    }),
    _createVNode(_component_Toast, {
      position: "bottom-center",
      group: "bc",
      pt: {
			container: 'w-[150px] mx-auto',
			content: 'flex items-center',
			detail: 'hidden',
			closeButton: 'hidden'
		}
    }),
    _createVNode(_component_router_view)
  ], 64))
}