import { createI18n } from 'vue-i18n';
import { getI18nMessages } from './language/language.model';

const i18n = createI18n({
	legacy: false,
	locale: localStorage.getItem('locale') ?? 'en',
	fallbackLocale: 'en',

	fallbackWarn: false,
	missingWarn: false,

	messages: getI18nMessages()
});

export default i18n;
