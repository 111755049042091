import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';

import { useStore } from '../store/main';

const routes = [
	{
		path: '/',
		// name: 'home',
		// component: HomeView
		component: () => import(/* webpackChunkName: "about" */ '../views/WelcomeView.vue'),
	},

	{
		path: '/disclaimer',
		component: () => import(/* webpackChunkName: "about" */ '../views/DisclaimerView.vue'),
	},

	{
		path: '/welcome',
		component: () => import(/* webpackChunkName: "about" */ '../views/WelcomeView.vue'),
	},

	{
		path: '/login',
		component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
	},

	{
		path: '/register',
		component: () => import(/* webpackChunkName: "about" */ '../views/RegisterView.vue'),
	},

	{
		path: '/forgot',
		component: () => import(/* webpackChunkName: "about" */ '../views/ForgotView.vue'),
	},

	// {
	// 	path: '/help',
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/HelpView.vue'),
	// },

	// {
	// 	path: '/privacy',
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyView.vue'),
	// },

	// {
	// 	path: '/terms',
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/TermsView.vue'),
	// },

	{
		path: '/model',
		component: () => import(/* webpackChunkName: "about" */ '../views/ModelView.vue'),
		meta: {
			isLogin: true,
		},
	},

	{
		path: '/inpatient',
		component: () => import(/* webpackChunkName: "about" */ '../views/CaseView.vue'),
		meta: {
			isLogin: true,
			// isLogin: false,
		},
	},

	// {
	// 	path: '/profile',
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/ProfileView.vue'),
	// 	meta: {
	// 		isLogin: true,
	// 	},
	// },

	// {
	// 	path: '/test',
	// 	component: () => import(/* webpackChunkName: "test" */ '../views/TestView.vue'),
	// 	meta: {
	// 		// isLogin: true,
	// 		isLogin: false,
	// 	},
	// },

	// {
	// 	path: '/welcome-jump-for-mobile',
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/WelcomeJumpForMobileView.vue'),
	// },

	{
		path: '/outpatient',
		component: () => import(/* webpackChunkName: "about" */ '../views/CaseOutpatientView.vue'),
		meta: {
			isLogin: true,
		},
	},

	{
		path: '/medical-scribe',
		component: () => import(/* webpackChunkName: "about" */ '../views/MedicalScribeView.vue'),
		meta: {
			isLogin: true,
		},
	},

	/*
	.##..........#####...##.......
	.##....##...##...##..##....##.
	.##....##..##.....##.##....##.
	.##....##..##.....##.##....##.
	.#########.##.....##.#########
	.......##...##...##........##.
	.......##....#####.........##.
	*/
	{
		path: '/:pathMatch(.*)*',
		component: () => import(/* webpackChunkName: "site" */ '../views/NotFoundView.vue'),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

async function checkUser() {
	window.isLogin = false;

	const token = localStorage.getItem('token');
	if (token) {
		const config = {
			headers: {
				authorization: `Bearer ${token}`,
			},
		};
		// console.log("🚀 ~ checkUser ~ config:", config)

		try {
			const response = await axios.get(window.apiUrl + '/appUserLogin/getUserProfile', config);
			// console.log("🚀 ~ checkUser ~ response:", response)

			const store = useStore();

			if (response.data.result) {
				try {
					const package0 = response.data.data.appUser.package[0];

					package0.creditExpiredDate = package0.creditExpired.substring(0, 10);

				} catch (e) {
					// yada
				}
				store.setUser(response.data.data.appUser);
				window.isLogin = true;

			} else {
				// not login
				window.isLogin = false;
				localStorage.removeItem('token');
			}
		} catch (e) {
			// remove token
			localStorage.removeItem('token');
			window.isLogin = false;

		}
	}
}

router.beforeEach(async (to, from, next) => {
	// console.log("🚀 ~ router.beforeEach ~ to:", to)
	// console.log("🚀 ~ router.beforeEach ~ from:", from)

	await checkUser();

	if (to.meta.isLogin) {
		if (window.isLogin === true) {
			next();
		} else {
			next('/');
		}
	} else {		
		if (window.isLogin) {
			next('/inpatient');
		} else {
			next();
		}
	}
});

export default router;
